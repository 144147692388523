.featureServiceContainer {
    >div:nth-child(1) {
        text-align: center;
        width: 60%;
        margin: auto;
        margin-block-end: 2rem;
    }

    .featureService {
        display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;

        .featureCard {
            // width: 32.5%;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 2%;
            text-align: center;
            border-radius: 0.8rem;
            margin-block-end: 1rem;
            position: relative;
            height: 35rem;
            overflow: hidden;


            &:hover {
                .featureCardInfo {
                    position: absolute;
                    top: 8%;
                    background-color: #6E1B5D;
                    width: 88%;
                    height: 30rem;
                    border-radius: 1rem;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    padding: 5%;
                    cursor: pointer;

                    h4,
                    h5 {
                        color: #ffffff;
                    }

                    h5 {
                        font-size: 0.8rem;
                    }
                }

                img {
                    filter: brightness(20%);
                }

            }

            img {
                width: 100%;
                transition: filter 0.3s ease;
            }

            .featureCardInfo {

                h4 {
                    color: #2E2E2E;
                    margin-block: 1rem;
                }

                h5 {
                    color: #808080;
                    font-size: 0.9rem;
                    font-weight: 300;
                    line-height: 1.6rem;
                }
            }
        }
    }

}

@media screen and (max-width:480px) {
    .featureServiceContainer {
        padding-inline: 6%;
        padding-block-start: 8%;

        >div:nth-child(1) {
            width: 100%;
        }

        .featureService {
            display: flex;
            flex-direction: column;
            margin-block: 2rem;

            .featureCard {
                width: 100%;
                height: 26.8rem;

                &:hover {

                    .featureCardInfo {
                        height: 26rem;
                        z-index: 1;
                        top: 2%;
                        width: 96%;

                    }

                }
            }
        }
    }

}