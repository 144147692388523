.welcomeToOtusoneContainer {
    padding-inline: 8%;

    >div:nth-child(1) {

        h5 {
            color: aqua;
        }
    }

    .cardContainer {
        display: flex;
        justify-content: space-between;
        margin-block: 2.5rem;

        >div {
            width: 32.5%;
            border: 1px solid #e3e3e3;
            background-color: #e3e3e3;
            border-radius: 0.5rem;

            img {
                width: 100%;
                height: 16rem;
                object-fit: cover;
                border-radius: 0.5rem;

            }

            h4 {
                margin-block: 1rem;
            }

            p {
                font-size: 0.8rem;
                line-height: 1.6rem;
                padding-block-end: 2rem;
                padding-inline: 2rem;

            }

        }
    }
}

@media screen and (max-width:480px) {
    .welcomeToOtusoneContainer {
        padding-inline: 6%;
        .cardContainer {
            display: flex;
            flex-direction: column;

            >div {
                width: 100%;
                margin-block: 1rem;
            }
        }

    }

}