.serviceBanner {
    position: relative;
    border-radius: 0.8rem;

    .image {
        height: 32rem;
        border-radius: 0.8rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.8rem;
        }
    }

    .opecity {
        height: 32rem;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        background-color: #000000;
        opacity: 0.4;
        border-radius: 0.8rem;
    }

    .content {
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2,
        h5 {
            // color: #6E1B5D;
            color: #ffffff;
        }

        h5 {
            margin-block: 1rem;
            font-size: 0.8rem;
        }

        >div {
            margin: auto;
        }

        .service {
            margin-block-start: 0.5rem;
            display: flex;
            justify-content: space-between;

            h5 {
                background-color: #6E1B5D;
                padding-inline: 1rem;
                padding-block: 0.3rem;
                border-radius: 0.25rem;
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .serviceBanner {

        .image,
        .opecity {
            height: 25rem;
            border-radius: 0;
        }

        .content {
            width: 100%;
            padding-inline: 6%;

            h5 {
                font-size: 1rem;
            }

            .service {
                display: none;

            }
        }

    }

}