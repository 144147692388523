.WorkAboutContainer {
    padding-inline: 12%;
    padding-block-start: 5%;

    h4 {
        width: 45%;
        line-height: 2rem;
        margin-block-start: 1.25rem;
    }

    h5 {
        color: #6E1B5D;
    }

    >div {
        display: flex;
        justify-content: space-between;
        margin-block: 3rem;

        >div:nth-child(1) {
            width: 35%;
            margin-block-start: 5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >div:nth-child(2) {
            width: 55%;
            position: relative;

            >div:nth-child(1) {
                display: flex;
                justify-content: space-between;

                >div:nth-child(1) {

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                >div:nth-child(2) {
                    width: fit-content;
                    height: fit-content;
                    margin-block: auto;
                }

            }

            >div:nth-child(2) {
                position: absolute;
                top: -7.15%;
                left: 41%;
                z-index: -1;
            }

            p {
                font-size: 0.9rem;
                width: 85%;
                position: absolute;
                bottom: 0%;
                line-height: 2rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .WorkAboutContainer {
        padding-inline: 6%;
        padding-block: 15%;

        h4 {
            width: 100%;
            line-height: 2rem;
            margin-block-start: 1.25rem;
        }

        >div {
            display: flex;
            justify-content: space-between;
            margin-block: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            >div:nth-child(1) {
                width: 100%;
            }

            >div:nth-child(2) {
                width: 100%;
                position: unset;

                >div:nth-child(1) {
                    display: flex;
                    flex-direction: column;

                    img {
                        width: 100%;
                        height: 100%;
                        margin-block-start: 2rem;
                    }

                    >div:nth-child(2) {
                        width: 100%;
                    }

                }

                >div:nth-child(2) {
                    display: none;
                }

                p {
                    font-size: 0.9rem;
                    width: 100%;
                    position: unset;
                    bottom: unset;
                    line-height: 2rem;
                    padding-block: 1.5rem;
                }
            }
        }
    }

}