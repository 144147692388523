.testimonialsContainer {
    padding-inline: 8%;
    padding-block: 5%;
    background-color: #FFFFFF;

    p {
        margin-block: 1rem;
    }

    >div {
        display: flex;
        justify-content: space-between;

        .testimonials {
            width: 32%;
            box-shadow: 0px 4px 8px 0px #0000001A;
            padding: 2%;
            border-radius: 0.25rem;
            margin-block-start: 2rem;


            .testimonialsRate {
                display: flex;

                >div:nth-child(1) {
                    img {
                        width: 40%;
                        margin-block: auto;
                    }

                    img[alt="Krishna Das Didige"] {
                        width: 22%;
                    }

                    img[alt="Jaideep Singh Rathore"] {
                        width: 80%;
                    }
                }

                >div:nth-child(2) {
                    display: flex;
                    margin-block: auto;

                    img {
                        margin-inline-start: 0.4rem;
                    }
                }


            }

            p {
                color: #000000;
                font-size: 0.8rem;
            }

            .testimonialsReviewer {
                // border: 1px solid blue;
                display: flex;

                >div:nth-child(1) {
                    margin-block: auto;

                    img {
                        width: 70%;
                        height: fit-content;
                    }
                }

                >div:nth-child(2) {
                    margin-block: auto;

                    h5,
                    p {
                        margin-block: 0;
                    }

                    p {
                        color: #494949;
                        font-size: 0.7rem;
                    }

                }
            }
        }

    }
}

@media screen and (max-width:480px) {
    .testimonialsContainer {
        padding-inline: 6%;
        padding-block: 5%;

        >div {
            .testimonials {
                width: 100%;
            }
        }
    }


}