.welcomeSectionContainer {
    padding-inline: 8%;
    color: #333333;

    >div:nth-child(1) {
        img {
            width: 100%;
            height: 100%;
        }
    }

    >div:nth-child(2) {
        margin: auto;

        h2 {
            margin-block: 0.8rem;
        }

        p {
            font-size: 0.8rem;
        }

        >div {
            margin-block: 1rem;

            button {
                font-size: 0.8rem;
                font-weight: 200;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .welcomeSectionContainer {
        padding-inline: 6%;
        padding-block: 5%;
    }


}