.careerContainer {
    background-color: #ffffff;

    .careerAbout {
        padding-inline: 8%;
        padding-block: 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card {
            width: 32%;
            text-align: center;

            h2 {
                color: #161C2D;
            }

            p {
                padding-inline: 15%;
                margin-block-start: 1rem;
                color: #161C2D;
            }

        }
    }

    .joinUs {
        padding-inline: 8%;

        >div:nth-child(1) {
            text-align: center;
            padding-inline: 25%;

        }

        .jobsCard {
            display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            margin-block-start: 5%;

            .card {
                // width: 32%;
                display: flex;
                margin-block-end: 1.6rem;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                padding: 1.6%;
                border-radius: 0.5rem;

                img {
                    margin-inline-end: 1rem;
                    border: 1px solid #e3e3e3;
                    border-radius: 0.5rem;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }

                h2 {
                    color: #2E2E2E;
                    margin-block-end: 1rem;
                }

                p {
                    color: #808080;
                }

                &:hover {
                    background: linear-gradient(97.1deg, #6E1B5D 22.14%, #B3125E 106.45%);

                    h2,
                    p {
                        color: #ffffff;
                    }


                }
            }

        }
    }
}

@media screen and (max-width:480px) {
    .careerContainer {
        .careerAbout {
            flex-direction: column;
            padding-inline: 6%;
            padding-block-start: 8%;

            .card {
                width: 100%;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                padding: 5%;
                margin-block-end: 1rem;
            }
        }

        .joinUs {
            padding-inline: 6%;
            padding-block: 8%;

            >div:nth-child(1) {
                text-align: center;
                padding-inline: 0%;

            }

            .jobsCard {
                flex-direction: column;

                .card {
                    width: 100%;
                    padding: 10%;
                }
            }
        }

    }

}