.keyFeaturesContainer {
    padding-inline: 8%;

    .keyCardContainer {
        display: flex;
        justify-content: space-between;
        margin-block: 2.5rem;

        .keyCard {
            display: flex;
            justify-content: space-between;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 2%;
            border-radius: 0.5rem;
            width: 32.5%;
            background-color: #ffffff;
            cursor: pointer;
            transition: background-color 0.03s ease;
            color: #6E1B5D;

            &:hover {
                background: linear-gradient(97.1deg, #6E1B5D 22.14%, #B3125E 106.45%);
                color: #ffffff;

            }

            div:nth-child(1) {
                height: fit-content;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }

            div:nth-child(2) {
                padding-inline: 3%;

                h4 {
                    font-size: 1rem;
                    font-weight: 700;
                }

                p {
                    font-size: 0.8rem;
                    padding-block-start: 0.6rem;
                }
            }
        }

    }
}

@media screen and (max-width:480px) {
    .keyFeaturesContainer {
        padding-inline: 6%;
        padding-block: 5%;
        .keyCardContainer {
            display: flex;
            flex-direction: column;
            margin-block: 0rem;

            .keyCard {
                width: 100%;
                margin-block: 0.5rem;
            }

        }

    }

}