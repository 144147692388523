.aboutBannerContainer {
    padding-inline: 8%;
    background-image: url('/asserst/img/about/BG.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-block: 2%;

    .content {
        margin: auto;

        >div {
            h4 {
                margin-block: 1rem;
                line-height: 2.8rem;
            }

            h5 {
                color: #B3125E;
            }

            p {
                font-size: 0.9rem;
                color: #161C2D;
                line-height: 1.8rem;
            }

            >div {
                margin-block-start: 1rem;

                button {
                    padding-inline: 2rem;
                    padding-block: 0.4rem;
                    font-size: 0.8rem;
                    font-weight: 100;
                }
            }

        }

    }

    .image {
        >div {
            margin-inline-start: auto;
            width: 22rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .aboutBannerContainer {
        padding-block: 10%;

        .image {
            margin-block-start: 2rem;

            >div {
                width: 100%;
            }
        }
    }

}