.ourRecentWorkContainer {
    .ourRecentWork {
        width: 40%;
        margin: auto;
        text-align: center;
        margin-block: 6rem;

        h4 {
            margin-block: 1rem;
        }

        p {
            font-size: 0.9rem;
            font-weight: 400;
            color: #494949;
        }
    }

    .recentWorkCard {
        padding-inline: 8%;
        display: flex;
        // justify-content: space-between;

        >div {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: 2%;
            // width: 32.5%;
            height: 32rem;
            margin-block-end: 1rem;
            position: relative;

            img {
                width: 100%;
                height: 15rem;
                margin-block-end: 1rem;
            }

            p {
                color: #808080;
                font-size: 0.9rem;
                margin-block-start: 1rem;
            }

            >div {
                // margin-block-end: auto;
                cursor: pointer;
                position: absolute;
                bottom: 5%;

            }
        }
    }

    .recentWorkCard+div {
        width: fit-content;
        margin: auto;
        margin-block-start: 2rem;
        margin-block-end: 5rem;

        button {
            border-radius: 0rem;
            padding-block: 0.5rem;
            padding-inline: 2rem;
        }
    }
}

@media screen and (max-width:480px) {
    .ourRecentWorkContainer {
        padding-inline: 6%;

        .ourRecentWork {
            width: 100%;
        }

        .recentWorkCard {
            flex-direction: column;
            padding-inline: 0;

            >div {
                width: 100%;
                height: fit-content;
                margin-block: 1rem;
                position: unset;
                padding: 5%;

                img {
                    height: 10rem;
                }

                >div {
                    margin-block-start: 1rem;
                    cursor: pointer;
                    position: unset;
                    // bottom: 5%;

                }
            }

        }
    }

}