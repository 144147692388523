.headingTextContainer {

    h2 {
        font-size: 2rem;
        font-weight: 700;

    }

    p {
        font-size: 0.8rem;
        padding-block: 1rem;
        line-height: 1.8rem;
        word-spacing: 0.02rem;
    }
}