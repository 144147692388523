.truestedByContainer {
    width: 100%;
    margin: auto;
    padding-inline: 8%;
    margin-block: 6rem;

    h4 {
        color: #667294;
        margin-block-end: 2rem;
    }

    >div:nth-child(1) {
        text-align: center;

        p {
            padding-inline: 20%;
        }
    }

    .imgCard {
        display: flex;
        justify-content: space-between;
        margin-block-start: 5%;

        .card {

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }

        >div:nth-child(7) {
            margin-inline-start: 1.5rem;
        }
    }
}

@media screen and (max-width:480px) {
    .truestedByContainer {
        padding-inline: 6%;
        // padding-block: 5%;
        >div:nth-child(1) {
            width: 100%;

            p {
                padding-inline: 0;
            }
        }

        .imgCard {
            display: flex;
            flex-direction: column;

            .card {
                width: 80%;
                margin: auto;
                margin-block: 1rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }

            >div:nth-child(6),
            >div:nth-child(7) {
                margin: auto;
                width: 60%;
                margin-block-end: 1rem;
            }
        }
    }


}