.whyChooseContainer {

    >div:nth-child(1) {
        text-align: center;
        width: 65%;
        margin: auto;
        margin-block-end: 2rem;
    }

    .whyChoose {
        .card {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            >div {
                border: 1px solid #e3e3e3;
                width: 32.5%;
                margin-block-end: 0.8rem;
                border-radius: 0.8rem;
                padding: 1%;
                display: flex;

                &:hover {
                    background-color: #ffffff;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                }

                >div {
                    padding-inline-start: 2.5%;

                    h4 {
                        color: #2E2E2E;
                    }

                    h5 {
                        font-size: 0.9rem;
                        font-weight: 300;
                        color: #808080;
                        line-height: 1.5rem;
                        margin-block-start: 1rem;
                    }
                }

            }
        }
    }
}

@media screen and (max-width:480px) {
    .whyChooseContainer {
        padding-inline: 6%;
        padding-block: 5%;

        >div:nth-child(1) {
            width: 100%;
        }

        .whyChoose {
            display: flex;
            flex-direction: column !important;

            .card>div {
                width: 100%;
                padding-block: 4%;
            }
        }
    }

}